<template>
	<div>
		<ktv-table
			id="listuser"
			:columns="columns"
			:is-loading="isLoading"
			:rows="rows"
			:total-rows="totalRecords"
			:mode="modeTable"
			:filter="false"
			:rows-per-page="serverParams.rowPerpage"
			:search-custom-enabled="true"
			search-placeholder="Search by Name"
			style="width:100%;"
			@on-column-filter="onColumnFilter"
			@on-page-change="onPageChange"
			@on-per-page-change="onPerPageChange"
			@on-search="onSearchFilter"
			@on-sort-change="onSortChange"
		/>
	</div>
</template>
<script>
	import { KtvTable } from "@/components"
	export default {
		components: {
			KtvTable
		},
		props: {
			groupId: {
				required: true,
				type: String,
			}
		},
		data() {
			return {
				modalsReg: false,
				isLoading: false,
				modeTable: "remote",
				columns: [
					{
						label: "Full Name",
						field: "UserRealName",
						thClass: "text-center",
						tdClass: "text-left",
					},
					{
						label: "User Name",
						field: "UserName",
						thClass: "text-center",
						tdClass: "text-left",
					},
					{
						label: "Active",
						field: "UserActive",
						thClass: "text-center",
						tdClass: "text-center",
					}
				],
				rows: [],
				totalRecords: 0,
				serverParams: {
					columnFilters: {
						GroupId: this.groupId,
					},
					sort: [{
						field: "GroupId",
						type: "ASC"
					}],
					currentPage: 1,
					rowPerpage: 10,
				},
			}
		},
		mounted() {
			this.loadItems()
		},
		methods: {
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			onPageChange(params) {
				this.updateParams({ currentPage: params.currentPage })
				this.loadItems()
			},
			onPerPageChange(params) {
				this.updateParams({ rowPerpage: params.currentPerPage })
				this.loadItems()
			},
			onSortChange(params) {
				this.updateParams({
					sort: [
						{
							type: params[0].type,
							field: params[0].field,
						},
					],
					currentPage: 1
				})
				this.loadItems()
			},
			onColumnFilter(params) {
				this.updateParams(params)
				this.loadItems()
			},
			onSearchFilter(params) {
				this.updateParams({
					columnFilters: { GroupId: params.searchTerm },
				})
				this.loadItems()
			},
			loadItems() {
				this.isLoading = true
				this.$http
					.Post(this.serverParams, this.$urlApi.group.listUserGroup)
					.then((response) => {
						this.isLoading = false
						if (response.success) {
							this.rows = response.results.data
							this.totalRecords = response.results.total
						} else {
							this.$swal("Error!", response.error_massage, "error")
						}
					})
					.catch((error) => {
						this.isLoading = false
						this.$swal("Error!", error.response.error_massage, "error")
					})
			},
		}
	}
</script>

<style>
.icon-choose:hover {
    transition: transform .2s; /* Animation */
    font-size: 16px;
    cursor: pointer;
    padding: 0;
    margin: 0;
}
.vgt-global-search__input--hidden {
	display: none !important;
}
</style>